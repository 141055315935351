<template>
  <div>
    <z-progress :g_show="first_show"/>
    <!--  https://donatepay.ru/widgets/donation -->

    <iframe src="https://widget.donatepay.ru/widgets/page/47253c567deec91aac0cf5f1ae278f41201722f0953e0cb95984bb3bb7017b50?widget_id=1224498&sum=200"
            @load="onLoadtab()" width="510" height="220" frameBorder="0">

    </iframe>
<!--    <iframe src="https://widget.donatepay.ru1/widgets/page/b5487eae03bb748b84e1fd93a9a661ee6eea77a7b87beef27840f4a1b1d08303?widget_id=1224498&sum=200"-->
<!--            @load="onLoadtab()" width="510" height="220" frameBorder="0">-->

<!--    </iframe>-->
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      first_show: true,
      steamID: this.$store.state.user.steam,
    }
  },
  methods: {
    // test() { alert("!!!!")},
    onLoadtab() {
      if (this.first_show) {
        this.first_show = false
      }
    },
  },
  components: {
    "z-progress": () => import('@/site/componets/progress'),
  }
}
</script>

<style scoped>

</style>